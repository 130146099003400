<template>
  <div class="helpCenter">
    <div class="helpCenter-header">
      <p class="helpCenter-header-title heading-small">Help Center</p>
      <img
        class="helpCenter-header-xicon"
        alt="x-icon"
        src="../../assets/x-icon.png"
        @click="closeHelpCenter"
      />
    </div>
    <div class="helpCenter-content">
      <div
        class="helpCenter-content-dropdown"
        @click="toggleDropdown('sensor')"
      >
        <div class="helpCenter-content-dropdown-header">
          <p class="helpCenter-content-dropdown-header-title">Sensor Status</p>
          <div class="helpCenter-content-dropdown-header-container">
            <img
              class="helpCenter-content-dropdown-header-container-arrow"
              alt="arrow-dropdown"
              :class="{
                'helpCenter-content-dropdown-header-container-arrow--rotate':
                  dropdowns.sensor,
              }"
              src="../../assets/arrow-dropdown.png"
            />
          </div>
        </div>
        <div
          class="helpCenter-content-dropdown-content"
          :class="{
            'helpCenter-content-dropdown-content--show': dropdowns.sensor,
          }"
        >
          <p class="helpCenter-content-dropdown-content-text">
            <b>How it's calculated:</b> Sensor data is sent to the dashboard on
            a 7 day interval. 4 weeks of data is analyzed to get a rolling
            average for each day of the week based on those 4 weeks. If the flow
            rate changes sharply below that daily average, then the sensor
            status will change to indicate abnormal behavior.
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b>Red (Attention Needed):</b> Indicates a sharp decline in water
            flow and attention needed. Sensors are flagged as red if flow rate
            drops below 25% of average for the day. The sensor will send data
            that night turning the status to red.
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b>Yellow (Abnormal Behavior):</b> Indicates abnormal behavior. More
            than 21 zero liter days recorded in the last 30 days.
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b>Green (Normal Operation):</b> Indicates normal behavior based on
            the most recent sensor data.
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b>Gray (Not Active): </b> Indicates that a pump has not been
            communicating for more than 30 Days.
          </p>
        </div>
      </div>

      <div
        class="helpCenter-content-dropdown"
        @click="toggleDropdown('terminology')"
      >
        <div class="helpCenter-content-dropdown-header">
          <p class="helpCenter-content-dropdown-header-title">
            Data Terminology
          </p>
          <div class="helpCenter-content-dropdown-header-container">
            <img
              class="helpCenter-content-dropdown-header-container-arrow"
              alt="arrow-dropdown"
              :class="{
                'helpCenter-content-dropdown-header-container-arrow--rotate':
                  dropdowns.terminology,
              }"
              src="../../assets/arrow-dropdown.png"
            />
          </div>
        </div>
        <div
          class="helpCenter-content-dropdown-content"
          :class="{
            'helpCenter-content-dropdown-content--show': dropdowns.terminology,
          }"
        >
          <p class="helpCenter-content-dropdown-content-text">
            <b>% Change in Liters:</b> Liters measured on the most recent day
            compared to the rolling average.
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b>Avg. Liters/Hour:</b> Average liters/hour for the most recent day
            of data.
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b>Avg. Liters Per Hour:</b> Average liters per hour based on the
            last 7 days of data
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b>Avg. Liters Per Day:</b> Average liters per day based on the last
            7 days of data
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b>Est. Beneficiaries:</b> Estimated number of people this water
            point may be serving based on measured liters
          </p>
          <p class="helpCenter-content-dropdown-content-text">
            <b># of 0 Liter Days:</b> Number of days that have less than 100
            liters in the last 30 days
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdowns: {
        sensor: false,
        terminology: false,
      },
    };
  },
  methods: {
    toggleDropdown(name) {
      this.dropdowns[name] = !this.dropdowns[name];
    },
    closeHelpCenter() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss">
.helpCenter {
  width: 30vw;
  overflow: auto; /* add overflow property to enable scrolling */
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 2em;
    border-bottom: 2px solid #f1f3f9;
    &-title {
      font-size: 20px;
      font-weight: 700;
    }
    &-xicon {
      width: 0.7em;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    padding: 0.5em 1em 0.5em 1em;
    padding: 2em;
    &-dropdown {
      margin-bottom: 2em;
      &-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #f8f9fc;
        &:hover {
          cursor: pointer;
        }
        &-title {
          padding-left: 1em;
          font-size: 16px;
        }
        &-container {
          padding-right: 1em;
          &-arrow {
            width: 1em;
            transition: transform 0.3s ease-in-out;
          }
          &-arrow--rotate {
            transform: rotate(180deg);
          }
        }
      }
      &-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        &--show {
          max-height: 100em; /* adjust this value to set the maximum height of the dropdown content */
        }
        &-text {
          font-size: 14px;
          padding-right: 1em;
          padding-left: 1em;
        }
      }
    }
  }
}
</style>
