<template>
  <form
    novalidate
    ref="sensorForm"
    class="actions-form"
    @submit.prevent="checkForm"
  >
    <div class="actions-form-header">
      <div v-if="isEditing">
        <p class="actions-form-header-title">Edit Sensor</p>
      </div>
      <div v-else>
        <p class="actions-form-header-title">Add Sensor</p>
      </div>
      <img
        class="actions-form-header-close"
        alt="x-icon"
        src="../../assets/x-icon.png"
        @click="closeModal"
      />
    </div>
    <fieldset :disabled="isLoading">
      <div class="actions-form-content">
        <!-- Sensor ID -->
        <div class="form-field">
          <label class="required" for="sensorId">Sensor ID</label>
          <input
            class="actions-form-content-left-input"
            type="text"
            id="sensorId"
            name="sensorId"
            required
            :disabled="isEditing"
            v-model="sensorData.sensorId"
          />
          <FormFieldError error="emptyRequiredField" />
        </div>
        <!-- IMEI -->
        <div class="form-field">
          <label class="required" for="imei">IMEI</label>
          <input
            type="text"
            id="imei"
            name="imei"
            required
            :disabled="isEditing"
            v-model="sensorData.imei"
          />
          <FormFieldError error="emptyRequiredField" />
        </div>

        <!-- ICCID -->
        <div class="form-field">
          <label class="required" for="ICCID">ICCID</label>
          <input
            type="text"
            id="iccid"
            name="iccid"
            required
            :disabled="isEditing"
            v-model="sensorData.iccid"
          />
          <FormFieldError error="emptyRequiredField" />
        </div>

        <!-- Model -->
        <div class="form-field">
          <label class="required" for="model">Model</label>
          <input
            type="text"
            id="model"
            name="model"
            required
            :disabled="isEditing"
            v-model="sensorData.model"
          />
          <FormFieldError error="emptyRequiredField" />
        </div>

        <!-- Partner Name -->
        <div class="form-field">
          <label class="required" for="partner">Partner Name</label>
          <select
            @change="setPartnerSelected"
            required
            v-model="partnerSelected.id"
            :disabled="isEditing || !isEditing"
          >
            <option :value="partnerSelected.id" :selected="true">
              {{ partnerSelected.name }}
            </option>
          </select>
        </div>
        <!-- Service Provider -->
        <div class="form-field">
          <label
            :class="{
              disabled: !hasServiceProviders,
            }"
            for="serviceProvider"
            >Service Provider</label
          >
          <select
            @change="setServiceProviderSelected"
            v-model="serviceProviderSelected.id"
            :disabled="!(selectedOrgIsPartner && hasServiceProviders)"
          >
            <option v-if="selectedOrgIsPartner" :value="null" />
            <option
              v-for="org in partnerSelected.serviceProviders"
              :value="org.id"
              :key="org.id"
              :selected="org.id === serviceProviderSelected.id"
            >
              {{ org.name }}
            </option>
          </select>
        </div>
        <!-- Country -->
        <div class="form-field">
          <label for="country">Country</label>
          <input
            type="text"
            id="country"
            name="country"
            v-model="sensorData.country"
          />
        </div>
        <!-- Water Point -->
        <div class="form-field">
          <label class="required" for="waterPoint">Water Point</label>
          <input
            type="text"
            id="waterPoint"
            name="waterPoint"
            required
            v-model="sensorData.waterPoint"
          />
          <FormFieldError error="emptyRequiredField" />
        </div>
        <!-- Community -->
        <div class="form-field">
          <label for="community">Community</label>
          <input
            type="text"
            id="community"
            name="community"
            v-model="sensorData.community"
          />
        </div>
        <!-- Admin Unit 1 -->
        <div class="form-field">
          <label for="administrativeUnit1">Admin Unit 1</label>
          <input
            type="text"
            id="administrativeUnit1"
            name="administrativeUnit1"
            v-model="sensorData.administrativeUnit1"
          />
        </div>
        <!-- Location Type -->
        <div class="form-field">
          <label for="locationType">Location type</label>
          <select id="locationType" v-model="sensorData.locationType">
            <option :value="null" />
            <option
              v-for="location in locationEnum"
              :value="location.value"
              :key="location.value"
            >
              {{ location.label }}
            </option>
          </select>
        </div>
        <!-- Install Date -->
        <div class="form-field">
          <label for="installationDate">Install Date</label>
          <input
            type="date"
            id="installationDate"
            name="installationDate"
            v-model="sensorData.installationDate"
          />
        </div>
        <!-- Latitude -->
        <div class="form-field">
          <label for="latitude">Latitude</label>
          <input
            type="text"
            id="latitude"
            name="latitude"
            v-model="sensorData.latitude"
          />
        </div>
        <!-- Longitude -->
        <div class="form-field">
          <label for="Longitude">Longitude</label>
          <input
            type="text"
            id="Longitude"
            name="Longitude"
            v-model="sensorData.longitude"
          />
        </div>
        <!-- Project ID -->
        <div class="form-field">
          <label for="projectId">Project ID</label>
          <input
            type="text"
            id="projectId"
            name="projectId"
            v-model="sensorData.projectId"
          />
        </div>
      </div>

      <div class="server-response" v-if="serverResponse">
        <p>{{ serverResponse }}</p>
      </div>
      <div class="actions-form-footer">
        <LoadingButton
          type="submit"
          text="Save"
          :isLoading="isLoading"
        ></LoadingButton>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { validateForm } from "./form-helpers";
import FormFieldError from "../Shared/FormFieldError.vue";
import LoadingButton from "../Shared/LoadingButton.vue";
import { ToastMessages } from "../Shared/strings";

export default {
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
    point: {
      type: Object,
      required: false,
    },
  },
  data() {
    const locationEnum = {
      school: {
        value: "School",
        label: "School",
      },
      community: {
        value: "Community",
        label: "Community",
      },
      church: {
        value: "Health Center",
        label: "Health Center",
      },
    };
    return {
      sensorData: {
        sensorId: "",
        iccid: "",
        partnerId: this.$store.getters.getSelectedOrg.id,
        partner: this.$store.getters.getSelectedOrg.name,
        country: "",
        community: "",
        serviceProviderId: "",
        serviceProvider: "",
        latitude: "",
        longitude: "",
        installationDate: "",
        locationType: "",
        waterPoint: "",
        administrativeUnit1: "",
        imei: "",
        model: "",
        projectId: "",
      },
      locationEnum,
      id: "",
      isEditing: true,
      partnerSelected: { name: "", id: "", serviceProviders: [] },
      serviceProviderSelected: { name: "", id: "" },
      serverResponse: "",
      isLoading: false,
    };
  },
  computed: {
    organizations() {
      return this.$store.state.loggedUser.organizations;
    },
    hasServiceProviders() {
      if (
        Object.keys(this.partnerSelected).length === 0 ||
        this.partnerSelected.serviceProviders?.length === 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    selectedOrgIsPartner() {
      return this.$store.getters.getSelectedOrg.type === "Partner";
    },
    loading() {
      return this.loaders > 0;
    },
  },
  methods: {
    setServiceProviderSelected(e) {
      const selected = e.currentTarget.selectedOptions[0];
      this.sensorData.serviceProvider = selected.label ?? null;
      this.sensorData.serviceProviderId = selected.value ?? null;
    },
    setPartnerSelected() {
      this.sensorData.partner = this.partnerSelected.name;
      this.sensorData.partnerId = this.partnerSelected.id;
      this.sensorData.serviceProvider = "";
      this.sensorData.serviceProviderId = "";
      this.serviceProviderSelected = {};
    },
    checkForm() {
      this.serverResponse = "";
      const formIsValid = validateForm([
        ...this.$refs.sensorForm.querySelectorAll("input, select"),
      ]);
      if (formIsValid) this.saveSensor();
    },
    async saveSensor() {
      this.isLoading = true;
      this.sensorData.latitude = parseFloat(this.sensorData.latitude);
      this.sensorData.longitude = parseFloat(this.sensorData.longitude);

      if (this.isEditing) {
        this.sensorData.id = this.id;
        const payload = { ...this.sensorData };
        delete payload["iccid"];
        delete payload["status"];
        delete payload["showInfoWindow"];
        delete payload["redFlag"];
        delete payload["lastRecorded"];
        delete payload["zeroDays"];
        delete payload["avgDailyLiters"];
        delete payload["beneficiaries"];
        delete payload["percentChange"];
        delete payload["dateDay"];
        delete payload["avgHourlyLiters"];
        delete payload["installation_date"];
        try {
          await this.$store.dispatch("editSensor", payload);
          this.$store.dispatch("showToast", {
            message: ToastMessages.sensor.edit.success,
            type: "success",
          });
          this.closeModal();
        } catch (errorMessage) {
          this.serverResponse = errorMessage.response.data.message;
        } finally {
          this.isLoading = false;
        }
      } else {
        try {
          await this.$store.dispatch("addSensor", this.sensorData);
          this.$store.dispatch("showToast", {
            message: ToastMessages.sensor.add.success,
            type: "success",
          });
          this.closeModal();
        } catch (errorMessage) {
          this.serverResponse = errorMessage.response.data.message;
        } finally {
          this.isLoading = false;
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  components: {
    FormFieldError,
    LoadingButton,
  },
  mounted() {
    const selectedOrg = this.$store.getters.getSelectedOrg;

    // Add Sesnor
    if (!this.isEdit) {
      this.isEditing = false;
      // Users not associated with partners cannot add sensors so no need to populate selectedOrg / selectedPartner
      if (selectedOrg.type === "Partner") {
        this.partnerSelected = selectedOrg;
      }
    }
    // Edit Sensor
    else {
      this.sensorData = { ...this.point };
      this.sensorData.installationDate =
        this.sensorData.installationDate?.split("T")[0] || "";
      this.id = this.sensorData.id || "";

      this.serviceProviderSelected = {
        name: this.sensorData.serviceProvider,
        id: this.sensorData.serviceProviderId,
      };
      if (selectedOrg.type === "Partner") {
        this.partnerSelected = selectedOrg;
      } else if (selectedOrg.type === "ServiceProvider") {
        this.partnerSelected = {
          name: this.sensorData.partner,
          id: this.sensorData.partnerId,
          serviceProviders: [selectedOrg],
        };
      } else {
        // eslint-disable-next-line
        console.error(`unknown organization type: ${selectedOrg.type}`);
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "../Shared/forms.scss";
.actions-form-header-close {
  img {
    padding: 10px;
  }
}
</style>
