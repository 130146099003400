<template>
  <Pill
    class="pill"
  >
    <template v-if="hasIcon" #icon>
      <svg v-if="locationType === 'School'" xmlns="http://www.w3.org/2000/svg" color="currentColor" viewBox="0 96 960 960"><path d="M479 941.652 183.348 780.391v-240L27.565 456 479 209.782 932.435 456v323.218h-73v-280.87l-84.217 42.043v240L479 941.652Zm0-322.131L778.173 456 479 295.479 181.827 456 479 619.521Zm0 239.435 222.652-123.043V583.74L479 702.218 256.348 581.74v154.173L479 858.956Zm1-239.435Zm-1 79.653Zm0 0Z"/></svg>
      <svg v-if="locationType === 'Community'" xmlns="http://www.w3.org/2000/svg" color="currentColor" viewBox="0 96 960 960"><path d="M60.782 906.174V489.782l246.217-246.783 246.783 246.783v416.392h-493Zm73-73h137v-120h73v120h137V519.87L306.999 346.652 133.782 519.87v313.304Zm137-217v-73h73v73h-73Zm383 290V456.782L430.955 234.521h111.654L726.218 418.13v488.044h-72.436Zm172.436 0V385.13L675.609 234.521h111.087l113.088 111.84v559.813h-73.566Zm-692.436-73h347-347Z"/></svg>
      <svg v-if="locationType === 'Church'" xmlns="http://www.w3.org/2000/svg" color="currentColor" viewBox="0 96 960 960"><path d="M70.39 1002V673.218l165.001-72.866V469.595l205-104.812v-82.565h-80v-79.219h80v-80h79.218v80h80v79.219h-80v82.565l205 104.812v130.757l165 72.866V1002h-363V835.218q0-19.469-13.537-33.039-13.536-13.569-32.956-13.569t-33.072 13.569q-13.653 13.57-13.653 33.039V1002h-363Zm79.219-79.782h204.564v-89q0-51.55 36.989-89.406 36.989-37.855 89.022-37.855 52.034 0 89.121 37.855 37.087 37.856 37.087 89.406v89h203.999v-201l-165-73.197V514.653L480 429.306l-165.391 85.347v133.368l-165 73.197v201Zm330.45-278.217q22.941 0 38.941-16.059 16-16.059 16-39 0-22.376-16.059-38.659-16.059-16.282-39-16.282T441 550.342q-16 16.342-16 38.718 0 22.941 16.059 38.941 16.059 16 39 16ZM480 675.262Z"/></svg>
    </template>
    <template #label>
      <span class="text label-medium">
        {{ locationType ?? "-" }}
      </span>
    </template>
</Pill>
</template>
<script>
import Pill from './Pill.vue'
export default {
  components: { Pill, },
  props: {
    hasIcon: {
      type: Boolean,
      default: false,
    },
    locationType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
};
</script>
<style scoped lang="scss">
.pill {
  border-radius: 4px;
  background-color: #E0F0FF;
  svg {
    height: 16px;
    width: 16px;
    display: inline-block;
    color: #003366;
  }
  .text {
    color: #003366;
  }
}
</style>
