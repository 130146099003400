<template>
  <div class="component">
    <button
      @click="showHelpCenter = !showHelpCenter"
      class="button button--transparent"
    >
      <img
        style="width: 14px; height: 14px"
        class="question-sign"
        alt="?"
        src="../../assets/question-sign.png"
      />
      Help
    </button>
    <div v-if="showAddSensorButton && canAddSensors" class="component-action">
      <button @click="showAddSensorDrawer = !showAddSensorDrawer">
        + Sensor
      </button>
    </div>
    <Drawer :show.sync="showHelpCenter">
      <template>
        <HelpCenter @close="showHelpCenter = false" />
      </template>
    </Drawer>
    <Drawer :show.sync="showAddSensorDrawer">
      <template>
        <SensorForm @close="showAddSensorDrawer = false" :isEdit="false" />
      </template>
    </Drawer>
  </div>
</template>

<script>
import HelpCenter from "./HelpCenter.vue";
import Drawer from "../Shared/Drawer.vue";
import SensorForm from "../Forms/SensorForm.vue";
export default {
  props: {
    showAddSensorButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const selectOptions = {
      actions: {
        value: "actions",
        label: "Actions",
        hidden: true,
      },
      addSensor: {
        value: "addSensor",
        label: "Add Sensor",
      },
      importMultipleSensors: {
        value: "import",
        label: "Import Multiple Sensors",
      },
    };
    return {
      showHelpCenter: false,
      showAddSensorDrawer: false,
      selectedOption: selectOptions.actions,
      selectOptions,
      showAddSensorDropdown: false,
      showBulkUploadModal: false,
    };
  },
  components: { HelpCenter, Drawer, SensorForm },
  computed: {
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
    canAddSensors() {
      return this.$store.getters.roleLoggedUser === "SuperAdmin";
    },
  },
  methods: {
    closeModal() {
      this.showAddSensorDropdown = false;
    },
    closeModalBulk() {
      this.showBulkUploadModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
.component {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  &-help {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    &:hover {
      cursor: pointer;
    }
    &-title {
      font-weight: 700;
      color: #222520;
    }
    .question-sign {
      width: var(--base-font-size);
    }
  }

  &-action {
    &-select {
      background-color: #003366;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      color: white;
      height: 2.75em;
      border-radius: 0.5em;
      width: 7em;
      display: block;
      border: 1px solid #333;
      font-family: inherit;
      padding-left: 1rem;
      background-size: 1em;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
