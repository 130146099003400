var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"helpCenter"},[_c('div',{staticClass:"helpCenter-header"},[_c('p',{staticClass:"helpCenter-header-title heading-small"},[_vm._v("Help Center")]),_c('img',{staticClass:"helpCenter-header-xicon",attrs:{"alt":"x-icon","src":require("../../assets/x-icon.png")},on:{"click":_vm.closeHelpCenter}})]),_c('div',{staticClass:"helpCenter-content"},[_c('div',{staticClass:"helpCenter-content-dropdown",on:{"click":function($event){return _vm.toggleDropdown('sensor')}}},[_c('div',{staticClass:"helpCenter-content-dropdown-header"},[_c('p',{staticClass:"helpCenter-content-dropdown-header-title"},[_vm._v("Sensor Status")]),_c('div',{staticClass:"helpCenter-content-dropdown-header-container"},[_c('img',{staticClass:"helpCenter-content-dropdown-header-container-arrow",class:{
              'helpCenter-content-dropdown-header-container-arrow--rotate':
                _vm.dropdowns.sensor,
            },attrs:{"alt":"arrow-dropdown","src":require("../../assets/arrow-dropdown.png")}})])]),_c('div',{staticClass:"helpCenter-content-dropdown-content",class:{
          'helpCenter-content-dropdown-content--show': _vm.dropdowns.sensor,
        }},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)])]),_c('div',{staticClass:"helpCenter-content-dropdown",on:{"click":function($event){return _vm.toggleDropdown('terminology')}}},[_c('div',{staticClass:"helpCenter-content-dropdown-header"},[_c('p',{staticClass:"helpCenter-content-dropdown-header-title"},[_vm._v(" Data Terminology ")]),_c('div',{staticClass:"helpCenter-content-dropdown-header-container"},[_c('img',{staticClass:"helpCenter-content-dropdown-header-container-arrow",class:{
              'helpCenter-content-dropdown-header-container-arrow--rotate':
                _vm.dropdowns.terminology,
            },attrs:{"alt":"arrow-dropdown","src":require("../../assets/arrow-dropdown.png")}})])]),_c('div',{staticClass:"helpCenter-content-dropdown-content",class:{
          'helpCenter-content-dropdown-content--show': _vm.dropdowns.terminology,
        }},[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("How it's calculated:")]),_vm._v(" Sensor data is sent to the dashboard on a 7 day interval. 4 weeks of data is analyzed to get a rolling average for each day of the week based on those 4 weeks. If the flow rate changes sharply below that daily average, then the sensor status will change to indicate abnormal behavior. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("Red (Attention Needed):")]),_vm._v(" Indicates a sharp decline in water flow and attention needed. Sensors are flagged as red if flow rate drops below 25% of average for the day. The sensor will send data that night turning the status to red. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("Yellow (Abnormal Behavior):")]),_vm._v(" Indicates abnormal behavior. More than 21 zero liter days recorded in the last 30 days. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("Green (Normal Operation):")]),_vm._v(" Indicates normal behavior based on the most recent sensor data. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("Gray (Not Active): ")]),_vm._v(" Indicates that a pump has not been communicating for more than 30 Days. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("% Change in Liters:")]),_vm._v(" Liters measured on the most recent day compared to the rolling average. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("Avg. Liters/Hour:")]),_vm._v(" Average liters/hour for the most recent day of data. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("Avg. Liters Per Hour:")]),_vm._v(" Average liters per hour based on the last 7 days of data ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("Avg. Liters Per Day:")]),_vm._v(" Average liters per day based on the last 7 days of data ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("Est. Beneficiaries:")]),_vm._v(" Estimated number of people this water point may be serving based on measured liters ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"helpCenter-content-dropdown-content-text"},[_c('b',[_vm._v("# of 0 Liter Days:")]),_vm._v(" Number of days that have less than 100 liters in the last 30 days ")])
}]

export { render, staticRenderFns }