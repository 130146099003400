<template>
  <span :class="`btn tooltip ${getDirection()}`">
    <slot></slot>
    <span class="tooltip-content">
      <span class="tooltip-title">{{ title }}</span>
      <span v-if="subtitle" class="tooltip-subtitle">{{ subtitle }}</span>
    </span>
  </span>
</template>

<script>
export default {
  data: () => ({
    directions: {
      top: "top",
      bottom: "bottom",
      left: "left",
      right: "right",
    },
  }),
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "top",
    },
  },
  methods: {
    getDirection() {
      // Just to make sure that we are always using one of the options
      // Mentioned in the directions var in data
      return this.directions[this.position] || this.directions.top;
    },
  },
};
</script>

<style scoped lang="scss">
$white: #ffffff;
$black: #000000;
$shadow: rgba(205, 210, 214, 0.8);

.tooltip-title {
  font-weight: 400;
  color: black;
}
.tooltip-subtitle {
  color: #a5a5a5;
  text-align: left;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: min-content;
  &:hover {
    .tooltip-content {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltip-content {
    visibility: hidden;
    background-color: $white;
    box-shadow: 0 5px 25px 5px $shadow;
    box-sizing: border-box;
    color: $black;
    font-size: 12px;
    line-height: 1.2;
    padding: 4px 8px;
    position: absolute;
    opacity: 1;
    transition: all 0.1s ease;
    max-width: 230px;
    width: max-content;

    display: flex;
    flex-direction: column;
    border-radius: 4px;
    z-index: 20;

    &::after {
      background-color: $white;
      content: "";
      height: 10px;
      position: absolute;
      transform: rotate(45deg);
      width: 10px;
    }
  }

  &.top {
    .tooltip-content {
      bottom: calc(100% + 0.5em);
      left: 50%;
      transform: translateX(-50%);
      &::after {
        bottom: -5px;
        left: 50%;
        margin-left: -5px;
      }
    }
  }

  &.bottom {
    .tooltip-content {
      top: calc(100% + 8px);
      transform: translateX(-50%);
      &::after {
        top: -5px;
        left: calc(50% + 4px);
      }
    }
  }

  &.right {
    .tooltip-content {
      left: calc(100% + 1.5em);
      top: 50%;
      transform: translateY(-50%);
      &::after {
        left: -5px;
        margin-top: -5px;
        top: 50%;
      }
    }
  }
  &.left {
    .tooltip-content {
      right: calc(100% + 1.5em);
      top: 50%;
      transform: translateY(-50%);
      &::after {
        right: -5px;
        margin-top: -5px;
        top: 50%;
      }
    }
  }
}
</style>
