<template>
  <ToolTip :position="position" :title="text">
    <svg class="info-icon" viewBox="0 -960 960 960">
      <path
        fill="currentColor"
        d="M440.413-284.413h79.174V-528h-79.174v243.587Zm39.376-310.804q17.244 0 29.119-11.665 11.875-11.664 11.875-28.907 0-17.244-11.665-29.119-11.664-11.875-28.907-11.875-17.244 0-29.119 11.665-11.875 11.664-11.875 28.907 0 17.244 11.665 29.119 11.664 11.875 28.907 11.875Zm.487 507.348q-81.189 0-152.621-30.618-71.432-30.618-124.991-84.177-53.559-53.559-84.177-124.949-30.618-71.391-30.618-152.845 0-81.455 30.618-152.387t84.177-124.491q53.559-53.559 124.949-84.177 71.391-30.618 152.845-30.618 81.455 0 152.387 30.618t124.491 84.177q53.559 53.559 84.177 124.716 30.618 71.156 30.618 152.344 0 81.189-30.618 152.621-30.618 71.432-84.177 124.991-53.559 53.559-124.716 84.177-71.156 30.618-152.344 30.618ZM480-170.87q129.043 0 219.087-90.043Q789.13-350.957 789.13-480t-90.043-219.087Q609.043-789.13 480-789.13t-219.087 90.043Q170.87-609.043 170.87-480t90.043 219.087Q350.957-170.87 480-170.87ZM480-480Z"
      />
    </svg>
  </ToolTip>
</template>
<script>
import ToolTip from "./ToolTip.vue";
export default {
  components: { ToolTip },
  props: {
    position: {
      type: String,
      default: "top",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped lang="scss">
.info-icon {
  cursor: pointer;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  margin-left: 2px;
  margin-bottom: 2px;
}
</style>
