<template>
  <Pill
    class="pill"
    :class="`${status.toLowerCase()}`"
  >
    <template #icon>
      <span class="circle">
      </span>
    </template>
    <template #label>
      <span class="text label-small">
        {{ formattedStatus }}
      </span>
    </template>
</Pill>
</template>
<script>
import Pill from './Pill.vue'
export default {
  components: { Pill, },
  props: {
    status: {
      type: String,
      default: "",
    },
  },
  computed: {
    formattedStatus() {
      return this.status === "Grey" ? "Not Active" : this.status;
    }
  },
};
</script>
<style scoped lang="scss">
.pill {
  border-radius: 100px;
  .circle {
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
}
.red {
  color: #e52e3c;
  background-color: #FEEAEB;
  .circle {
    background-color: #e52e3c;
  }
}

.grey {
  color: #474d59;
  background-color: #efefef;
  .circle {
    background-color: #474d59;
  }
}

.yellow {
  color: #b58f05;
  background-color: #f0e6bd;
  .circle {
    background-color: #b58f05;
  }
}

.green {
  color: #44a352;
  background-color: #dffde1;
  .circle {
    background-color: #44a352;
  }
}
</style>
